.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-background {
  /*height: 100vh;*/
  /*width: 100vw;*/
  background-color: #282c34;
  background-image:
          linear-gradient(to top right, rgba(96, 43, 134, 1) 0, transparent 40%),
          linear-gradient(to top left, rgba(50, 21, 123, 1) 0, transparent 40%);
  background-size: 100% 200%;
  /*animation: gradient-animation 5s ease infinite alternate backwards;*/
}

._App-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
  background-color: #282c34;
  background-image: url("block-pattern.svg");
  background-size: 112px 200px;
  opacity: .4;
}

@keyframes gradient-animation {
  80% {
    background-position: 0 50%;
  }
  100% {
    background-position: 50% 70%;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
